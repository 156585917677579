import { DiHtml5, DiCss3, DiJavascript1, DiNodejs, DiJava, DiPhp, DiPython, DiGit, DiLinux, DiUnitySmall, DiMysql, DiPhotoshop, DiAws } from "react-icons/di";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

const Home = () => {

    const [aboutMe, showAboutMe] = useState(false);

    return (
        <>
            <MetaTags>
                <title>Home | Nuby - Dev</title>
            </MetaTags>
            <div className="text-center mx-4 mb-16 animate-fade-in-up lg:mt-72">
                <p className="text-gray-800 text-4xl mt-4">Welcome to my site!</p>
                <p className="text-gray-700 text-2xl my-6 sm:my-2">I'm Alex, and I do full-stack development for a living.</p>
                <p className="text-gray-700 text-2xl mb-2 sm:mb-0">If you would like to contact me, please e-mail me at <a href="mailto:alex@nuby.dev" className="text-blue-600 hover:underline hover:text-blue-900">alex@nuby.dev</a></p>
                <button onClick={() => showAboutMe(!aboutMe)} className="ease-in duration-200 mt-4 mb-4 py-2 px-6 font-semibold rounded border border-gray-600 text-gray-600 bg-transparent hover:bg-gray-600 hover:text-white hover:border-transparent">About me</button>
                {(aboutMe &&
                    <div className="animate-fade-in-up">
                        <p className="text-gray-700 text-xl mb-4">
                            I have experience in the following languages:
                        </p>
                        <DiHtml5 className="text-red-500 w-28 h-28 inline-flex" title="HTML5" />
                        <DiCss3 className="text-blue-600 w-28 h-28 inline-flex" title="CSS3" />
                        <DiJavascript1 className="text-yellow-400 w-28 h-28 inline-flex" title="JavaScript" />
                        <DiNodejs className="text-green-600 w-28 h-28 inline-flex" title="Node.js" />
                        <DiJava className="text-red-600 w-28 h-28 inline-flex" title="Java" />
                        <DiPhp className="text-indigo-900 w-28 h-28 inline-flex" title="PHP" />
                        <DiPython className="text-gray-600 w-28 h-28 inline-flex" title="Python" />
                        <p className="text-gray-700 text-xl mb-4">
                            <br />
                            And I'm also familiar with:
                        </p>
                        <DiGit className="text-red-500 w-28 h-28 inline-flex" title="Git" />
                        <DiLinux className="text-black w-28 h-28 inline-flex" title="Linux" />
                        <DiMysql className="text-gray-300 w-28 h-28 inline-flex" title="MySQL" />
                        <DiUnitySmall className="text-black w-28 h-28 inline-flex" title="Unity + C#" />
                        <DiPhotoshop className="text-black w-28 h-28 inline-flex" title="Photoshop" />
                        <DiAws className="text-black w-28 h-28 inline-flex" title="Amazon Web Services" />
                    </div>)
                    || (<div className="animate-fade-in">
                        <p className="text-xs">Interested in my public GitHub projects? <Link to="/projects"><span className="text-blue-600 hover:underline hover:text-blue-900">Click here!</span></Link></p>
                    </div>)
                }
            </div>
        </>
    );
}

export default Home;