import { FaGithub } from "react-icons/fa";
import { Octokit } from "octokit";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { MetaTags } from 'react-meta-tags';

const Projects = () => {

    const [resultData, setResultData] = useState([]);
    const projects = [];

    useEffect(() => {
        (new Octokit()).request('GET /users/alexbarariu/repos').then(res => setResultData(res.data));
    }, []);

    resultData?.map((item, id) => {
        const newProjects = [...projects];

        newProjects.name = item.name;
        newProjects.desc = item.description;
        newProjects.src = item.homepage;
        newProjects.gitSrc = item.html_url;
        newProjects.language = item.language;
        newProjects.lastUpdatedAt = item.updated_at;

        projects.push(newProjects);
        return null;
    });

    return (
        <>
            <MetaTags>
                <title>My Projects | Nuby - Dev</title>
                <meta name="description" content="The place to find my public GitHub repositories" />
            </MetaTags>
            <div className="animate-fade-in-up text-center mt-4 mx-4 mb-16">
                <p className="text-gray-800 text-4xl">My projects</p>
                <p className="mt-4 text-gray-700 text-2xl">Below you may find a list of projects I've been working on.</p>
                <p className="mt-2 text-gray-700 text-xl">This list contains all of <a target="_blank" rel="noreferrer" href="https://github.com/alexbarariu" className="text-blue-600 hover:underline hover:text-blue-800">my public Github repositories.</a></p>
                <div className="grid grid-cols-10">
                    {(projects?.map((project, id) => {
                        return (
                            <div key={id} className="text-gray-800 mt-4 bg-white rounded-lg leading-relaxed border border-gray-300 border-opacity-40 shadow col-start-1 col-end-11 text-left p-2 md:col-start-2 md:col-end-10 sm:leading-normal lg:col-start-3 lg:col-end-9">
                                {project.name}{project.desc && ` - ${project.desc}`}
                                <br />
                                {project.language && <span className="border border-gray-200 bg-gray-200 rounded-lg py-px px-1 text-gray-500">{project.language}</span>}
                                {project.src && <a href={project.src} className="ml-1 rounded py-px px-1 text-gray-500 hover:text-gray-900 ease-in-out duration-300">Live demo</a>}
                                {project.src && project.gitSrc && <span>-</span>}
                                <a href={project.gitSrc} target="_blank" rel="noreferrer" title="GitHub link" className="ml-2"><FaGithub className="inline-flex rounded -mt-1 text-gray-500 hover:text-gray-900 ease-in-out duration-300 w-4 h-4" /></a>
                                <span className="pt-4 pr-2 float-right sm:pt-0">Last updated on {project.lastUpdatedAt && format(new Date(project.lastUpdatedAt), "dd MMM yyyy h:mmaaa OOOO")}</span>
                            </div>
                        )
                    })) || (
                            <div className="animate-pulse mt-4 bg-white rounded-lg leading-relaxed border border-gray-300 border-opacity-40 shadow col-start-1 col-end-11 text-left p-2 md:col-start-2 md:col-end-10 sm:leading-normal lg:col-start-3 lg:col-end-9">
                                <span className="bg-gray-200 rounded-md w-1/3 h-5 float-left" />
                                <br />
                                <span className="bg-gray-200 rounded-md w-1/4 h-5 mt-1 float-left" />
                                <span className="bg-gray-200 rounded-md w-5/12 h-5 mt-1 float-right" />
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}

export default Projects;