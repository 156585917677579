import { useState } from "react";
import { FaGithub, FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {

    const [showLinks, setShowLinks] = useState(false);

    return (
        <>
            <footer className="animate-fade-in fixed bottom-0 bg-gray-200 text-gray-700 grid grid-cols-10 w-full py-3 text-sm sm:text-base">
                <span className="col-span-10 inline-flex ml-4">
                    <button onClick={() => setShowLinks(!showLinks)} className="font-semibold">Links</button>
                    <div className="overflow-hidden">
                        <span className={`inline-block transition duration-330${showLinks ? " opacity-100 ease-out" : " transform -translate-x-40 opacity-0 ease-hide"}`}>
                            &nbsp;-&nbsp;
                            <Link to="/"><span className="hover:text-gray-900">Home</span></Link>
                            &nbsp;-&nbsp;
                            <Link to="/projects"><span className="hover:text-gray-900">My Projects</span></Link>
                        </span>
                    </div>
                </span>
                <span className="col-start-11 inline-flex">
                    <p>Get in touch</p>
                    <a className="mt-px mr-1 ml-1" target="_blank" rel="noreferrer" href="https://github.com/alexbarariu"><FaGithub title="My GitHub page" className="footer-icon hover:text-gray-900" /></a>
                    <a className="mt-px mr-1 ml-1" href="mailto:alex@nuby.dev"><FaRegEnvelope title="My e-mail" className="footer-icon hover:text-gray-900" /></a>
                </span>
            </footer>
        </>
    );
}

export default Footer;