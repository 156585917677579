import { useState } from "react";

const Giveaways = () => {

    const [email, setEmail] = useState("");

    const messages = [
        "Sign up for the giveaway!",
        "Oops, didn't quite get that! Try again.",
        "Check your e-mail. We all make mistakes sometimes.",
        "Maybe someday this will work?",
        "Nah, just kidding. Keep pressing.",
        "One more? One more.",
        "Are you sure that e-mail address of yours is right?",
        "Get a life, stop pressing!",
        "Are you seriously still here? CLICKING ME?!",
        "I'm starting to get tired of you, leave me alone!",
        "I'm begging you to stop, please stop!",
        "I'm going to leave you if you keep pressing me.",
        "STOP!!!!!!!",
        "OK fine, bye!"
    ];

    const [currentMessage, setCurrentMessage] = useState(0);

    const handleClick = () => {
        fetch(`https://api.nuby.dev/giveaways`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        }).then(response => response.json())
            .then(data =>
                (data.isEven &&
                    window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ"))
                || (setCurrentMessage(currentMessage + 1))
            )
            .catch(() => console.log("An application error occurred!"));
    }

    return (
        <>
            <div className="text-center mt-12 sm:mt-80 animate-fade-in-up">
                <p className="text-gray-800 text-4xl">Please fill in your e-mail address below</p>
                <p className="text-gray-600 text-lg">By entering the "giveaway", you agree to your e-mail address being stored.</p>
                <p className="text-gray-600 text-lg">Your e-mail will only be used internally for statistical purposes, and will not be shared.</p>
                <input className="rounded-md shadow mt-4 p-2 border-gray-900" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="E-mail" />
                <br />
                {messages.length !== currentMessage &&
                    <button className="text-lg mt-4 text-gray-600 font-semibold ease-in-out duration-300 hover:text-gray-900 hover:underline" onClick={handleClick}>{messages[currentMessage]}</button>
                }
            </div>
        </>
    );
}

export default Giveaways;