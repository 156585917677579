import "./App.css";

import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";

import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import Footer from "./components/common/Footer";
import Projects from "./components/pages/Projects";
import WordCounter from "./components/pages/WordCounter";
import Giveaways from "./components/pages/Giveaways";

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/projects" component={Projects} />
                    <Route path="/word-counter" component={WordCounter} />
                    <Route path="/giveaways" component={Giveaways} />
                    <Route exact path="/" component={Home} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </Router>
        </>
    );
}

export default App;