import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';

const NotFound = () => {
    return (
        <>
            <MetaTags>
                <title>404 - Page Not Found | Nuby - Dev</title>
            </MetaTags>
            <div className="text-center mt-4 sm:mt-80 animate-fade-in-up">
                <p className="text-gray-800 text-4xl">Uh oh!</p>
                <p className="mt-4 text-gray-700 text-2xl">It seems like you ran into a 404 page. What were you doing here anyway?</p>
                <Link to="/"><button className="text-lg mt-4 text-gray-600 font-semibold ease-in-out duration-300 hover:text-gray-900 hover:underline">Take me back home!</button></Link>
            </div>
        </>
    );
}

export default NotFound;